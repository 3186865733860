windowReady.ready(function () {
  let data = {
    maquina_1: {
      serie: "N11547",
      maquina: "MELAF9401",
      capacidad: 100,
      valores: [
        {
          carga: 228,
          porcentaje: 70,
        },
        {
          carga: 393,
          porcentaje: 80,
        },
        {
          carga: 404,
          porcentaje: 90,
        },
        {
          carga: 362,
          porcentaje: 100,
        },
        {
          carga: 223,
          porcentaje: 110,
        },
        {
          carga: 90,
          porcentaje: 120,
        },
        {
          carga: 133,
          porcentaje: 130,
        },
      ],
    },
    maquina_2: {
      serie: "N11548",
      maquina: "MELAF9402",
      capacidad: 100,
      valores: [
        {
          carga: 19,
          porcentaje: 70,
        },
        {
          carga: 531,
          porcentaje: 80,
        },
        {
          carga: 522,
          porcentaje: 90,
        },
        {
          carga: 340,
          porcentaje: 100,
        },
        {
          carga: 364,
          porcentaje: 110,
        },
        {
          carga: 288,
          porcentaje: 120,
        },
        {
          carga: 110,
          porcentaje: 130,
        },
      ],
    },
    maquina_3: {
      serie: "81150",
      maquina: "MELAF9408",
      capacidad: 140,
      valores: [
        {
          carga: 356,
          porcentaje: 70,
        },
        {
          carga: 477,
          porcentaje: 80,
        },
        {
          carga: 497,
          porcentaje: 90,
        },
        {
          carga: 329,
          porcentaje: 100,
        },
        {
          carga: 359,
          porcentaje: 110,
        },
        {
          carga: 116,
          porcentaje: 120,
        },
        {
          carga: 118,
          porcentaje: 130,
        },
      ],
    },
    maquina_4: {
      serie: "81151",
      maquina: "MELAF9409",
      capacidad: 140,
      valores: [
        {
          carga: 18,
          porcentaje: 70,
        },
        {
          carga: 383,
          porcentaje: 80,
        },
        {
          carga: 583,
          porcentaje: 90,
        },
        {
          carga: 576,
          porcentaje: 100,
        },
        {
          carga: 171,
          porcentaje: 110,
        },
        {
          carga: 26,
          porcentaje: 120,
        },
        {
          carga: 102,
          porcentaje: 130,
        },
      ],
    },
    maquina_5: {
      serie: "81162",
      maquina: "MELAF9410",
      capacidad: 140,
      valores: [
        {
          carga: 266,
          porcentaje: 70,
        },
        {
          carga: 422,
          porcentaje: 80,
        },
        {
          carga: 593,
          porcentaje: 90,
        },
        {
          carga: 569,
          porcentaje: 100,
        },
        {
          carga: 329,
          porcentaje: 110,
        },
        {
          carga: 183,
          porcentaje: 120,
        },
        {
          carga: 147,
          porcentaje: 130,
        },
      ],
    },
    maquina_6: {
      serie: "81163",
      maquina: "MELAF9411",
      capacidad: 140,
      valores: [
        {
          carga: 179,
          porcentaje: 70,
        },
        {
          carga: 568,
          porcentaje: 80,
        },
        {
          carga: 592,
          porcentaje: 90,
        },
        {
          carga: 574,
          porcentaje: 100,
        },
        {
          carga: 374,
          porcentaje: 110,
        },
        {
          carga: 146,
          porcentaje: 120,
        },
        {
          carga: 99,
          porcentaje: 130,
        },
      ],
    },
    maquina_7: {
      serie: "81166",
      maquina: "MELAF9412",
      capacidad: 140,
      valores: [
        {
          carga: 400,
          porcentaje: 70,
        },
        {
          carga: 498,
          porcentaje: 80,
        },
        {
          carga: 411,
          porcentaje: 90,
        },
        {
          carga: 480,
          porcentaje: 100,
        },
        {
          carga: 384,
          porcentaje: 110,
        },
        {
          carga: 146,
          porcentaje: 120,
        },
        {
          carga: 55,
          porcentaje: 130,
        },
      ],
    },
    maquina_8: {
      serie: "81169",
      maquina: "MELAF9413",
      capacidad: 140,
      valores: [
        {
          carga: 226,
          porcentaje: 70,
        },
        {
          carga: 462,
          porcentaje: 80,
        },
        {
          carga: 569,
          porcentaje: 90,
        },
        {
          carga: 442,
          porcentaje: 100,
        },
        {
          carga: 353,
          porcentaje: 110,
        },
        {
          carga: 142,
          porcentaje: 120,
        },
        {
          carga: 82,
          porcentaje: 130,
        },
      ],
    },
    maquina_9: {
      serie: "81216",
      maquina: "MELAF9414",
      capacidad: 140,
      valores: [
        {
          carga: 238,
          porcentaje: 70,
        },
        {
          carga: 484,
          porcentaje: 80,
        },
        {
          carga: 518,
          porcentaje: 90,
        },
        {
          carga: 307,
          porcentaje: 100,
        },
        {
          carga: 395,
          porcentaje: 110,
        },
        {
          carga: 178,
          porcentaje: 120,
        },
        {
          carga: 96,
          porcentaje: 130,
        },
      ],
    },
    maquina_10: {
      serie: "81217",
      maquina: "MELAF9415",
      capacidad: 140,
      valores: [
        {
          carga: 255,
          porcentaje: 70,
        },
        {
          carga: 578,
          porcentaje: 80,
        },
        {
          carga: 542,
          porcentaje: 90,
        },
        {
          carga: 493,
          porcentaje: 100,
        },
        {
          carga: 317,
          porcentaje: 110,
        },
        {
          carga: 173,
          porcentaje: 120,
        },
        {
          carga: 114,
          porcentaje: 130,
        },
      ],
    },
    maquina_11: {
      serie: "81149",
      maquina: "MELAF9417",
      capacidad: 140,
      valores: [
        {
          carga: 57,
          porcentaje: 70,
        },
        {
          carga: 378,
          porcentaje: 80,
        },
        {
          carga: 485,
          porcentaje: 90,
        },
        {
          carga: 419,
          porcentaje: 100,
        },
        {
          carga: 199,
          porcentaje: 110,
        },
        {
          carga: 123,
          porcentaje: 120,
        },
        {
          carga: 83,
          porcentaje: 130,
        },
      ],
    },
    maquina_12: {
      serie: "81227",
      maquina: "MELAF9418",
      capacidad: 140,
      valores: [
        {
          carga: 285,
          porcentaje: 70,
        },
        {
          carga: 406,
          porcentaje: 80,
        },
        {
          carga: 412,
          porcentaje: 90,
        },
        {
          carga: 589,
          porcentaje: 100,
        },
        {
          carga: 184,
          porcentaje: 110,
        },
        {
          carga: 297,
          porcentaje: 120,
        },
        {
          carga: 118,
          porcentaje: 130,
        },
      ],
    },
  };

  let width = 1800;
  let height = 800;

  let svg = null;
  const ejeX = d3.scaleBand();
  const ejeY = d3.scaleLinear();

  function setup() {
    _.each(data, function (d, k) {
      calcularCargas(d);
    });

    let divs = d3.selectAll("[data-balance]");
    divs.each(function (d, i) {
      let div = d3.select(this);
      let key = div.attr("data-balance");
      let box = crearSVG(div);
      //let arreglo = ajustarData(data[key]);
      d3.select(div.node().parentNode)
        .select("h3")
        .html(data[key].maquina)
        .style("color", "#363A58");

      crearEjes(data[key]);
      crearGrafica(data[key]);
      crearCurva(data[key]);
    });
  }

  function calcularCargas(data) {
    let capacidad = data.capacidad;
    let valores = data.valores;

    let ponderados = _.map(
      valores,
      (d) => ((d.porcentaje * capacidad) / 100) * d.carga
    );
    data.produccion = _.sum(ponderados);

    let sub = _.filter(data.valores, (d) => d.porcentaje < 100);
    ponderados = _.map(
      sub,
      (d) => (((100 - d.porcentaje) * capacidad) / 100) * d.carga
    );
    data.subproduccion = _.sum(ponderados);
  }

  function setup0() {
    let wrapper = d3.select("#balance");
    if (wrapper.node()) {
      let box = crearSVG(wrapper);
      crearEjes();
      crearGrafica();
    }
  }

  function ajustarData(data) {
    let arreglo = [];
    let sum = 0;
    data.forEach(function (d) {
      let p = d.porcentaje;
      let c = d.carga;
      if (d.porcentaje <= 70) {
        sum += d.carga;
      }
      if (d.porcentaje >= 70) {
        arreglo.push({ carga: d.carga, porcentaje: d.porcentaje });
      }
    });
    arreglo[0]["carga"] = sum;
    return arreglo;
  }

  function crearSVG(wrapper) {
    let box = new Box({
      size: { width: width + 60, height: height + 150 },
      //padding: { top: 10, right: 40, bottom: 90, left: 140 },
      padding: { top: 10, right: 390, bottom: 90 + 150, left: 160 },
    });
    svg = wrapper
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);
    svg = svg
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);
    return box;
  }

  function crearEjes(data) {
    data = data.valores;
    let max = d3.max(data.map((data) => data.carga));

    ejeX
      .range([0, width])
      .domain(
        data.map(function (d) {
          return d.porcentaje;
        })
      )
      .padding(0.4);
    let gAxisX = svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(ejeX)
          .tickSizeOuter(0)
          .tickFormat(function (d) {
            let label = `${d - 10}% - ${d}%`;
            switch (d) {
              case 70:
                label = `${0}% - ${d}%`;
                break;
              case 130:
                label = `>${d - 10}%`;
                break;
              default:
                break;
            }
            return label;
          })
      );
    gAxisX.selectAll("text").style("font-size", "40px").attr("fill", "#363A58");
    gAxisX.selectAll("path").attr("stroke", "#363A58");
    gAxisX.selectAll("line").attr("stroke", "#363A58");
    gAxisX
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "60px")
      .attr("fill", "#363A58")
      .attr("x", width / 2)
      .attr("y", 120)
      .text("Rated payload");

    ejeY.range([height, 0]).domain([0, max]);
    let gAxisY = svg.append("g").call(d3.axisLeft(ejeY).tickSizeOuter(0));
    gAxisY.selectAll("text").style("font-size", "40px").attr("fill", "#363A58");
    gAxisY.selectAll("path").attr("stroke", "#363A58");
    gAxisY.selectAll("line").attr("stroke", "#363A58");

    gAxisY
      .append("text")
      .attr("text-anchor", "end")
      .style("font-size", "60px")
      .attr("fill", "#363A58")
      .attr("transform", "rotate(-90)")
      .attr("y", -120)
      .attr("x", -220)
      .text("Cycles");
  }

  function crearGrafica(data) {
    let titulo = null;
    if (data.hasOwnProperty("titulo")) {
      titulo = data.titulo;
    } else {
      titulo = `${data.maquina} (${data.serie})`;
    }
    let h2 = d3.select(svg.node().closest("div.unit")).select("h2");
    if (h2 || false) {
      h2.html(titulo);
    } else {
      d3.select(svg.node().closest("div.unit")).select("h3").html(titulo);
    }

    data = data.valores;

    let sum = d3.sum(data.map((data) => data.carga));
    data.forEach(function (d) {
      d.rel = (d.carga * 100) / sum;
    });

    let gBars = svg.append("g");

    gBars
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return ejeX(d.porcentaje);
      })
      .attr("width", ejeX.bandwidth())
      .attr("y", function (d) {
        return ejeY(0);
      })
      .attr("height", function (d) {
        return height - ejeY(0);
      })
      .attr("fill", function (d) {
        let color = "#F6EACA";
        switch (d.porcentaje) {
          case 100:
          case 110:
            color = "#7FA26F";
            break;
          case 120:
            color = d.rel <= 9 ? "#7FA26F" : "#BB5233";
            break;
          case 130:
            color = "#BB5233";
            break;
          default:
            break;
        }
        return color;
      })
      .transition()
      .duration(600)
      .attr("y", function (d) {
        return ejeY(d.carga);
      })
      .attr("height", function (d) {
        return height - ejeY(d.carga);
      })
      .delay(function (d, i) {
        return i * 50;
      });
  }

  //https://gist.github.com/phil-pedruco/88cb8a51cdce45f13c7e
  function gaussian(x, mean, sigma) {
    let gaussianConstant = 1 / Math.sqrt(2 * Math.PI);

    x = (x - mean) / sigma;
    return (gaussianConstant * Math.exp(-0.5 * x * x)) / sigma;
  }

  function normal() {
    let x = 0,
      y = 0,
      rds,
      c;
    do {
      x = Math.random() * 2 - 1;
      y = Math.random() * 2 - 1;
      rds = x * x + y * y;
    } while (rds == 0 || rds > 1);
    c = Math.sqrt((-2 * Math.log(rds)) / rds); // Box-Muller transform
    return x * c; // throw away extra sample y * c
  }

  function crearCurva() {
    let arreglo = [];
    let mean = ejeX(110) + ejeX.bandwidth() / 2;
    let sigma = 70;

    let r = d3.randomNormal(mean, sigma);
    arreglo.push({ q: 0, p: 0 });
    for (let i = 1; i <= 2000; i++) {
      let q = r();
      //let q = mean;
      let p = gaussian(q, mean, sigma); // calc prob of rand draw
      let el = {
        q: q,
        p: p,
      };
      arreglo.push(el);
    }
    arreglo.push({ q: width, p: 0 });

    arreglo.sort(function (x, y) {
      return x.q - y.q;
    });

    let escalaX = d3.scaleLinear().domain([0, width]).range([0, width]);
    let escalaY = d3
      .scaleLinear()
      .domain(d3.extent(arreglo, (d) => d.p))
      .range([height, 0]);
    var line = d3
      .line()
      .x((d) => escalaX(d.q))
      .y((d) => escalaY(d.p));

    let gLinea = svg.append("g").attr("id", "gLinea");

    gLinea
      .append("path")
      .datum(arreglo)
      .attr("class", "line")
      .style("fill", "none")
      .style("stroke", "#EFAE52")
      .style("stroke-width", 4)
      .attr("d", line);
  }

  function getUnderload(data) {
    let sum = 0;
    data.forEach(function (d) {
      if (d.porcentaje <= 110) {
        sum += d.rel;
      }
    });
    sum = d3.format(".2f")(sum);
    return sum;
  }

  function getLoad(data) {
    let sum = 0;
    data.forEach(function (d) {
      if (d.porcentaje == 120) {
        sum = d.rel;
      }
    });
    sum = d3.format(".2f")(sum);
    return sum;
  }

  function getOverload(data) {
    let sum = 0;
    data.forEach(function (d) {
      if (d.porcentaje == 130) {
        sum = d.rel;
      }
    });
    sum = d3.format(".2f")(sum);
    return sum;
  }

  function crearGrafica0() {
    let main = svg.append("g");
    main
      .selectAll("line")
      .data(data)
      .join("line")
      .attr("x1", function (d) {
        return ejeX(d.standard);
      })
      .attr("x2", function (d) {
        return ejeX(d.standard);
      })
      .attr("y1", function (d) {
        return ejeY(d.categoria);
      })
      .attr("y2", function (d) {
        return ejeY(d.categoria);
      })
      .attr("stroke", "#363A58")
      .attr("stroke-width", "1px")
      .transition()
      .duration(600)
      .attr("x2", function (d) {
        return ejeX(d.propio);
      });

    main
      .selectAll("circle.propio")
      .data(data)
      .join("circle")
      .attr("class", "propio")
      .attr("cx", function (d) {
        return ejeX(d.standard);
      })
      .attr("cy", function (d) {
        return ejeY(d.categoria);
      })
      .attr("r", "6")
      .style("fill", function (d) {
        let color = "#BB5233";
        if (d.propio <= d.standard) {
          color = "#7FA26F";
        }
        return color;
      })
      .transition()
      .duration(600)
      .attr("cx", function (d) {
        return ejeX(d.propio);
      });

    main
      .selectAll("circle.standard")
      .data(data)
      .join("circle")
      .attr("class", "standard")
      .attr("cx", function (d) {
        return ejeX(d.standard);
      })
      .attr("cy", function (d) {
        return ejeY(d.categoria);
      })
      .attr("r", "6")
      .style("fill", "#4C4082");
  }

  setup();
});
