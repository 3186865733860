windowReady.ready(function () {
  let data = [
    {
      categoria: "Excavator",
      standard: 350,
      propio: 270,
    },
    {
      categoria: "Dump truck",
      standard: 255.5,
      propio: 293,
    },
    {
      categoria: "Grader",
      standard: 275,
      propio: 187,
    },
    {
      categoria: "Loader",
      standard: 123,
      propio: 223,
    },
  ];

  let width = 1500;
  let height = 800;
  let box = null;

  let svg = null;
  const ejeX = d3.scaleLinear();
  const ejeY = d3.scaleBand();

  function setup() {
    let wrapper = d3.select("[data-type='fuel_consumption']");
    if (wrapper.node()) {
      crearSVG(wrapper);
      crearEjes();
      crearGrafica();
      crearLeyenda();
    }
  }

  function crearSVG(wrapper) {
    box = new Box({
      size: { width: width, height: height },
      //padding: { top: 10, right: 40, bottom: 90, left: 140 },
      padding: { top: 10, right: 400, bottom: 90, left: 140 },
    });
    width = box.content.width;
    height = box.content.height;
    svg = wrapper
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);
    svg = svg
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);
  }

  function crearEjes() {
    ejeX.domain([0, 400]).range([0, width]);
    let gAxisX = svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(ejeX).tickFormat((d) => `${d} L/h`));
    gAxisX.selectAll("path").style("display", "none").style("opacity", 0);
    gAxisX.selectAll("text").style("font-size", "24px").attr("fill", "#182935");
    gAxisX.selectAll("path").attr("stroke", "black");
    gAxisX.selectAll("line").style("display", "none").style("opacity", 0);

    ejeY
      .range([0, height])
      .domain(
        data.map(function (d) {
          return d.categoria;
        })
      )
      .padding(1);
    let gAxisY = svg.append("g").call(d3.axisLeft(ejeY));
    gAxisY.selectAll("path").style("display", "none").style("opacity", 0);
    gAxisY.selectAll("text").style("font-size", "24px").attr("fill", "#182935");
    gAxisY.selectAll("path").attr("stroke", "black");
    gAxisY.selectAll("line").style("display", "none").style("opacity", 0);
  }

  function crearGrafica() {
    let main = svg.append("g");

    main
      .selectAll("line.tick")
      .data(data)
      .join("line")
      .attr("class", "tick")
      .attr("x1", ejeX.range()[0])
      .attr("x2", ejeX.range()[1])
      .attr("y1", function (d) {
        return ejeY(d.categoria);
      })
      .attr("y2", function (d) {
        return ejeY(d.categoria);
      })
      .attr("stroke", "#EEEEEE")
      .attr("stroke-width", "2px");

    main
      .selectAll("line.compare")
      .data(data)
      .join("line")
      .attr("class", "compare")
      .attr("x1", function (d) {
        return ejeX(d.standard);
      })
      .attr("x2", function (d) {
        return ejeX(d.standard);
      })
      .attr("y1", function (d) {
        return ejeY(d.categoria);
      })
      .attr("y2", function (d) {
        return ejeY(d.categoria);
      })
      .attr("stroke", "#9E9E9E")
      .attr("stroke-width", "2px")
      .transition()
      .duration(600)
      .attr("x2", function (d) {
        return ejeX(d.propio);
      });

    main
      .selectAll("circle.propio")
      .data(data)
      .join("circle")
      .attr("class", "propio")
      .attr("cx", function (d) {
        return ejeX(d.standard);
      })
      .attr("cy", function (d) {
        return ejeY(d.categoria);
      })
      .attr("r", "10")
      .style("fill", function (d) {
        let color = "#BB5233";
        if (d.propio <= d.standard) {
          color = "#7FA26F";
        }
        return color;
      })
      .transition()
      .duration(600)
      .attr("cx", function (d) {
        return ejeX(d.propio);
      });

    main
      .selectAll("circle.standard")
      .data(data)
      .join("circle")
      .attr("class", "standard")
      .attr("cx", function (d) {
        return ejeX(d.standard);
      })
      .attr("cy", function (d) {
        return ejeY(d.categoria);
      })
      .attr("r", "10")
      .style("fill", "#F6EACA");
  }

  function crearLeyenda() {
    let gLeyenda = svg.append("g").attr("transform", `translate(1100, 150)`);
    //.attr("id", "gLeyenda");

    gLeyenda
      .append("rect")
      .attr("fill", "transparent")
      .attr("stroke", "#363A58")
      .attr("x", 0)
      .attr("y", -14)
      .attr("width", 200)
      .attr("height", 440);

    gLeyenda
      .append("rect")
      .attr("x", 60)
      .attr("y", 35)
      .attr("width", 80)
      .attr("height", 50)
      .attr("fill", "#7FA26F");

    gLeyenda
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 100)
      .attr("y", 105)
      .text("Industry");

    gLeyenda
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 100)
      .attr("y", 125)
      .text("average");

    gLeyenda
      .append("rect")
      .attr("x", 60)
      .attr("y", 165)
      .attr("width", 80)
      .attr("height", 50)
      .attr("fill", "#F6EACA");

    gLeyenda
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 100)
      .attr("y", 235)
      .text("Below");

    gLeyenda
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 100)
      .attr("y", 255)
      .text("average");

    gLeyenda
      .append("rect")
      .attr("x", 60)
      .attr("y", 295)
      .attr("width", 80)
      .attr("height", 50)
      .attr("fill", "#BB5233");

    gLeyenda
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 100)
      .attr("y", 365)
      .text("Above");

    gLeyenda
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 100)
      .attr("y", 385)
      .text("average");
  }

  setup();
});
