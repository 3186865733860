windowReady.ready(function () {
  let data = {
    todas: {
      titulo: "Production and loading balance",
      valores: [
        {
          carga: 2527,
          porcentaje: 70,
        },
        {
          carga: 5580,
          porcentaje: 80,
        },
        {
          carga: 6128,
          porcentaje: 90,
        },
        {
          carga: 5480,
          porcentaje: 100,
        },
        {
          carga: 3652,
          porcentaje: 110,
        },
        {
          carga: 1908,
          porcentaje: 120,
        },
        {
          carga: 1257,
          porcentaje: 130,
        },
      ],
    },
    maquina_1: {
      serie: "N11547",
      maquina: "MELAF9401",
      capacidad: 100,
      valores: [
        {
          carga: 228,
          porcentaje: 70,
        },
        {
          carga: 393,
          porcentaje: 80,
        },
        {
          carga: 404,
          porcentaje: 90,
        },
        {
          carga: 362,
          porcentaje: 100,
        },
        {
          carga: 223,
          porcentaje: 110,
        },
        {
          carga: 90,
          porcentaje: 120,
        },
        {
          carga: 133,
          porcentaje: 130,
        },
      ],
    },
    maquina_2: {
      serie: "N11548",
      maquina: "MELAF9402",
      capacidad: 100,
      valores: [
        {
          carga: 19,
          porcentaje: 70,
        },
        {
          carga: 531,
          porcentaje: 80,
        },
        {
          carga: 522,
          porcentaje: 90,
        },
        {
          carga: 340,
          porcentaje: 100,
        },
        {
          carga: 364,
          porcentaje: 110,
        },
        {
          carga: 288,
          porcentaje: 120,
        },
        {
          carga: 110,
          porcentaje: 130,
        },
      ],
    },
    maquina_3: {
      serie: "81150",
      maquina: "MELAF9408",
      capacidad: 140,
      valores: [
        {
          carga: 356,
          porcentaje: 70,
        },
        {
          carga: 477,
          porcentaje: 80,
        },
        {
          carga: 497,
          porcentaje: 90,
        },
        {
          carga: 329,
          porcentaje: 100,
        },
        {
          carga: 359,
          porcentaje: 110,
        },
        {
          carga: 116,
          porcentaje: 120,
        },
        {
          carga: 118,
          porcentaje: 130,
        },
      ],
    },
    maquina_4: {
      serie: "81151",
      maquina: "MELAF9409",
      capacidad: 140,
      valores: [
        {
          carga: 18,
          porcentaje: 70,
        },
        {
          carga: 383,
          porcentaje: 80,
        },
        {
          carga: 583,
          porcentaje: 90,
        },
        {
          carga: 576,
          porcentaje: 100,
        },
        {
          carga: 171,
          porcentaje: 110,
        },
        {
          carga: 26,
          porcentaje: 120,
        },
        {
          carga: 102,
          porcentaje: 130,
        },
      ],
    },
    maquina_5: {
      serie: "81162",
      maquina: "MELAF9410",
      capacidad: 140,
      valores: [
        {
          carga: 266,
          porcentaje: 70,
        },
        {
          carga: 422,
          porcentaje: 80,
        },
        {
          carga: 593,
          porcentaje: 90,
        },
        {
          carga: 569,
          porcentaje: 100,
        },
        {
          carga: 329,
          porcentaje: 110,
        },
        {
          carga: 183,
          porcentaje: 120,
        },
        {
          carga: 147,
          porcentaje: 130,
        },
      ],
    },
    maquina_6: {
      serie: "81163",
      maquina: "MELAF9411",
      capacidad: 140,
      valores: [
        {
          carga: 179,
          porcentaje: 70,
        },
        {
          carga: 568,
          porcentaje: 80,
        },
        {
          carga: 592,
          porcentaje: 90,
        },
        {
          carga: 574,
          porcentaje: 100,
        },
        {
          carga: 374,
          porcentaje: 110,
        },
        {
          carga: 146,
          porcentaje: 120,
        },
        {
          carga: 99,
          porcentaje: 130,
        },
      ],
    },
    maquina_7: {
      serie: "81166",
      maquina: "MELAF9412",
      capacidad: 140,
      valores: [
        {
          carga: 400,
          porcentaje: 70,
        },
        {
          carga: 498,
          porcentaje: 80,
        },
        {
          carga: 411,
          porcentaje: 90,
        },
        {
          carga: 480,
          porcentaje: 100,
        },
        {
          carga: 384,
          porcentaje: 110,
        },
        {
          carga: 146,
          porcentaje: 120,
        },
        {
          carga: 55,
          porcentaje: 130,
        },
      ],
    },
    maquina_8: {
      serie: "81169",
      maquina: "MELAF9413",
      capacidad: 140,
      valores: [
        {
          carga: 226,
          porcentaje: 70,
        },
        {
          carga: 462,
          porcentaje: 80,
        },
        {
          carga: 569,
          porcentaje: 90,
        },
        {
          carga: 442,
          porcentaje: 100,
        },
        {
          carga: 353,
          porcentaje: 110,
        },
        {
          carga: 142,
          porcentaje: 120,
        },
        {
          carga: 82,
          porcentaje: 130,
        },
      ],
    },
    maquina_9: {
      serie: "81216",
      maquina: "MELAF9414",
      capacidad: 140,
      valores: [
        {
          carga: 238,
          porcentaje: 70,
        },
        {
          carga: 484,
          porcentaje: 80,
        },
        {
          carga: 518,
          porcentaje: 90,
        },
        {
          carga: 307,
          porcentaje: 100,
        },
        {
          carga: 395,
          porcentaje: 110,
        },
        {
          carga: 178,
          porcentaje: 120,
        },
        {
          carga: 96,
          porcentaje: 130,
        },
      ],
    },
    maquina_10: {
      serie: "81217",
      maquina: "MELAF9415",
      capacidad: 140,
      valores: [
        {
          carga: 255,
          porcentaje: 70,
        },
        {
          carga: 578,
          porcentaje: 80,
        },
        {
          carga: 542,
          porcentaje: 90,
        },
        {
          carga: 493,
          porcentaje: 100,
        },
        {
          carga: 317,
          porcentaje: 110,
        },
        {
          carga: 173,
          porcentaje: 120,
        },
        {
          carga: 114,
          porcentaje: 130,
        },
      ],
    },
    maquina_11: {
      serie: "81149",
      maquina: "MELAF9417",
      capacidad: 140,
      valores: [
        {
          carga: 57,
          porcentaje: 70,
        },
        {
          carga: 378,
          porcentaje: 80,
        },
        {
          carga: 485,
          porcentaje: 90,
        },
        {
          carga: 419,
          porcentaje: 100,
        },
        {
          carga: 199,
          porcentaje: 110,
        },
        {
          carga: 123,
          porcentaje: 120,
        },
        {
          carga: 83,
          porcentaje: 130,
        },
      ],
    },
    maquina_12: {
      serie: "81227",
      maquina: "MELAF9418",
      capacidad: 140,
      valores: [
        {
          carga: 285,
          porcentaje: 70,
        },
        {
          carga: 406,
          porcentaje: 80,
        },
        {
          carga: 412,
          porcentaje: 90,
        },
        {
          carga: 589,
          porcentaje: 100,
        },
        {
          carga: 184,
          porcentaje: 110,
        },
        {
          carga: 297,
          porcentaje: 120,
        },
        {
          carga: 118,
          porcentaje: 130,
        },
      ],
    },
  };

  let width = 1800;
  let height = 800;

  let svg = null;
  const ejeX = d3.scaleBand();
  const ejeY = d3.scaleLinear();

  function setup() {
    _.each(data, function (d, k) {
      if (k !== "todas") {
        calcularCargas(d);
      }
    });
    calcularTodas();

    let wrapper = d3.select("[data-type='loading_productivity_vertical']");
    let box = crearSVG(wrapper);

    crearEjes(data.todas);
    crearGrafica(data.todas);
    crearCurva(data.todas);
    crearLeyenda(box, data.todas);
  }

  function calcularTodas() {
    let todas = data.todas;
    let produccion = 0;
    let subproduccion = 0;
    delete data.todas;
    _.each(todas.valores, function (o) {
      let carga = 0;
      _.each(data, function (m) {
        produccion += m.produccion;
        subproduccion += m.subproduccion;
        let v = _.find(m.valores, { porcentaje: o.porcentaje });
        if (v || false) {
          carga += v.carga;
        }
      });
      o.carga = carga;
    });
    todas.produccion = produccion;
    todas.subproduccion = subproduccion;
    data.todas = todas;
  }

  function calcularCargas(data) {
    let capacidad = data.capacidad;
    let valores = data.valores;

    let ponderados = _.map(
      valores,
      (d) => ((d.porcentaje * capacidad) / 100) * d.carga
    );
    data.produccion = _.sum(ponderados);

    let sub = _.filter(data.valores, (d) => d.porcentaje < 100);
    ponderados = _.map(
      sub,
      (d) => (((100 - d.porcentaje) * capacidad) / 100) * d.carga
    );
    data.subproduccion = _.sum(ponderados);
  }

  function crearSVG(wrapper) {
    let box = new Box({
      size: { width: width, height: height },
      //padding: { top: 10, right: 40, bottom: 90, left: 140 },
      padding: { top: 210, right: 40, bottom: 90, left: 140 },
    });
    width = box.content.width;
    height = box.content.height;
    svg = wrapper
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);
    svg = svg
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);
    return box;
  }

  function crearEjes(data) {
    data = data.valores;
    let max = d3.max(data.map((data) => data.carga));

    ejeX
      .range([0, width])
      .domain(
        data.map(function (d) {
          return d.porcentaje;
        })
      )
      .padding(0.4);
    let gAxisX = svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(ejeX)
          .tickSizeOuter(0)
          .tickFormat(function (d) {
            let label = `${d - 10}% - ${d}%`;
            switch (d) {
              case 70:
                label = `${0}% - ${d}%`;
                break;
              case 130:
                label = `>${d - 10}%`;
                break;
              default:
                break;
            }
            return label;
          })
      );
    gAxisX.selectAll("text").style("font-size", "20px").attr("fill", "#363A58");
    gAxisX.selectAll("path").attr("stroke", "#363A58");
    gAxisX.selectAll("line").attr("stroke", "#363A58");
    gAxisX
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "26px")
      .attr("fill", "#363A58")
      .attr("x", width / 2)
      .attr("y", 80)
      .text("Rated payload");

    ejeY.range([height, 0]).domain([0, max]);
    let gAxisY = svg.append("g").call(d3.axisLeft(ejeY).tickSizeOuter(0));
    gAxisY.selectAll("text").style("font-size", "20px").attr("fill", "#363A58");
    gAxisY.selectAll("path").attr("stroke", "#363A58");
    gAxisY.selectAll("line").attr("stroke", "#363A58");

    gAxisY
      .append("text")
      .attr("text-anchor", "end")
      .style("font-size", "26px")
      .attr("fill", "#363A58")
      .attr("transform", "rotate(-90)")
      .attr("y", -90)
      .attr("x", -220)
      .text("Cycles");
  }

  function crearGrafica(data) {
    data = data.valores;

    let sum = d3.sum(data.map((data) => data.carga));
    data.forEach(function (d) {
      d.rel = (d.carga * 100) / sum;
    });

    let gBars = svg.append("g");

    gBars
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return ejeX(d.porcentaje);
      })
      .attr("width", ejeX.bandwidth())
      .attr("y", function (d) {
        return ejeY(0);
      })
      .attr("height", function (d) {
        return height - ejeY(0);
      })
      .attr("fill", function (d) {
        let color = "#F6EACA";
        switch (d.porcentaje) {
          case 100:
          case 110:
            color = "#7FA26F";
            break;
          case 120:
            color = d.rel <= 9 ? "#7FA26F" : "#BB5233";
            break;
          case 130:
            color = "#BB5233";
            break;
          default:
            break;
        }
        return color;
      })
      .transition()
      .duration(600)
      .attr("y", function (d) {
        return ejeY(d.carga);
      })
      .attr("height", function (d) {
        return height - ejeY(d.carga);
      })
      .delay(function (d, i) {
        return i * 50;
      });
  }

  //https://gist.github.com/phil-pedruco/88cb8a51cdce45f13c7e
  function gaussian(x, mean, sigma) {
    let gaussianConstant = 1 / Math.sqrt(2 * Math.PI);

    x = (x - mean) / sigma;
    return (gaussianConstant * Math.exp(-0.5 * x * x)) / sigma;
  }

  function crearCurva() {
    let arreglo = [];
    let mean = ejeX(110) + ejeX.bandwidth() / 2;
    let sigma = 70;

    let r = d3.randomNormal(mean, sigma);
    arreglo.push({ q: 0, p: 0 });
    for (let i = 1; i <= 2000; i++) {
      let q = r();
      //let q = mean;
      let p = gaussian(q, mean, sigma); // calc prob of rand draw
      let el = {
        q: q,
        p: p,
      };
      arreglo.push(el);
    }
    arreglo.push({ q: width, p: 0 });

    arreglo.sort(function (x, y) {
      return x.q - y.q;
    });

    let escalaX = d3.scaleLinear().domain([0, width]).range([0, width]);
    let escalaY = d3
      .scaleLinear()
      .domain(d3.extent(arreglo, (d) => d.p))
      .range([height, 0]);
    var line = d3
      .line()
      .x((d) => escalaX(d.q))
      .y((d) => escalaY(d.p));

    let gLinea = svg.append("g").attr("id", "gLinea");

    gLinea
      .append("path")
      .datum(arreglo)
      .attr("class", "line")
      .style("fill", "none")
      .style("stroke", "#EFAE52")
      .style("stroke-width", 4)
      .attr("d", line);
  }

  function crearLeyenda(box, data) {
    data.ideal = data.produccion + data.subproduccion;
    data.produccion = data.produccion.toLocaleString();
    data.subproduccion = data.subproduccion.toLocaleString();
    data.ideal = data.ideal.toLocaleString();
    data.underload = getUnderload(data.valores);
    data.load = getLoad(data.valores);
    data.overload = getOverload(data.valores);

    let contenedor = d3.select(svg.node().closest("div.container"));
    contenedor.select("p[data-underload]").html(`${data.underload}%`);
    contenedor.select("p[data-load]").html(`${data.load}%`);
    contenedor.select("p[data-overload]").html(`${data.overload}%`);

    let gLeyenda = svg.append("g").attr("transform", `translate(355, -170)`);
    //.attr("id", "gLeyenda");

    gLeyenda
      .append("rect")
      .attr("fill", "transparent")
      .attr("stroke", "#363A58")
      .attr("x", 30)
      .attr("y", 10)
      .attr("width", 845)
      .attr("height", 100);

    gLeyenda
      .append("rect")
      .attr("x", 60)
      .attr("y", 35)
      .attr("width", 80)
      .attr("height", 50)
      .attr("fill", "#F6EACA");

    gLeyenda
      .append("text")
      .attr("text-anchor", "start")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 150)
      .attr("y", 70)
      .text("Underloading");

    gLeyenda
      .append("rect")
      .attr("x", 350)
      .attr("y", 35)
      .attr("width", 80)
      .attr("height", 50)
      .attr("fill", "#7FA26F");

    gLeyenda
      .append("text")
      .attr("text-anchor", "start")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 440)
      .attr("y", 70)
      .text("Desired load");

    gLeyenda
      .append("rect")
      .attr("x", 640)
      .attr("y", 35)
      .attr("width", 80)
      .attr("height", 50)
      .attr("fill", "#BB5233");

    gLeyenda
      .append("text")
      .attr("text-anchor", "start")
      .style("font-size", "22px")
      .attr("fill", "#363A58")
      .attr("x", 730)
      .attr("y", 70)
      .text("Overloading");
  }

  function getUnderload(data) {
    let sum = 0;
    data.forEach(function (d) {
      if (d.porcentaje <= 110) {
        sum += d.rel;
      }
    });
    sum = d3.format(".2f")(sum);
    return sum;
  }

  function getLoad(data) {
    let sum = 0;
    data.forEach(function (d) {
      if (d.porcentaje == 120) {
        sum = d.rel;
      }
    });
    sum = d3.format(".2f")(sum);
    return sum;
  }

  function getOverload(data) {
    let sum = 0;
    data.forEach(function (d) {
      if (d.porcentaje == 130) {
        sum = d.rel;
      }
    });
    sum = d3.format(".2f")(sum);
    return sum;
  }

  setup();
});
