windowReady.ready(function () {
  let data = [
    {
      maquina: "Máquina 4",
      productividad: 426.5,
      consumo: 126.4,
      digging: 70,
      hoist: 37,
      breaker: 3,
      att: 2,
      travel: 75,
    },
    {
      maquina: "Máquina 5",
      productividad: 420.9,
      consumo: 59.5,
      digging: 61,
      hoist: 114,
      breaker: 2,
      att: 1,
      travel: 116,
    },
    {
      maquina: "Máquina 6",
      productividad: 417.2,
      consumo: 76.3,
      digging: 94,
      hoist: 61,
      breaker: 10,
      att: 0,
      travel: 106,
    },
  ];

  let radarCategories = [
    { key: "digging", name: "Digging" },
    { key: "hoist", name: "Hoist" },
    { key: "breaker", name: "ATT Breaker" },
    { key: "att", name: "ATT Except for Breaker" },
    { key: "travel", name: "Travel" },
  ];

  let maquina = {
    maquina: "",
    productividad: null,
    consumo: null,
    digging: 0,
    hoist: 0,
    breaker: 0,
    att: 0,
    travel: 0,
  };
  let width = 1500;
  let height = 1000;
  let svgScatterplot = null;
  const ejeX = d3.scaleLinear();
  const ejeY = d3.scaleLinear();

  let widthRadar = 750;
  let heightRadar = 1000;
  let svgRadar = null;
  let gMainRadar = null;
  let radialScale = d3.scaleLinear().domain([0, 120]).range([0, 300]);
  let ticks = [20, 40, 60, 80, 100, 120];

  function setup() {
    let wrapperScatterplot = d3.select("#scatterplot_dump_trucks");
    if (wrapperScatterplot.node()) {
      let box = crearSVGScatterplot(wrapperScatterplot);
      crearEjesScatterplot(box);
      crearScatterplot();
    }

    let wrapperRadar = d3.select("#radar_dump_trucks");
    if (wrapperRadar.node()) {
      crearSVGRadar(wrapperRadar);
      crearEjesRadar();
      crearRadar();
    }
  }

  function crearSVGScatterplot(wrapper) {
    let box = new Box({
      size: { width: width, height: height },
      padding: { top: 10, right: 40, bottom: 90, left: 170 },
    });
    width = box.content.width;
    height = box.content.height;
    svgScatterplot = wrapper
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);
    svgScatterplot = svgScatterplot
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);
    return box;
  }

  function crearEjesScatterplot(box) {
    ejeX.domain([0, 200]).range([0, width]);
    let gAxisX = svgScatterplot
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(ejeX)
          .tickSizeOuter(0)
          .tickFormat((d) => `${d} L/h`)
      );
    gAxisX.selectAll("text").style("font-size", "24px").attr("fill", "#363A58");
    gAxisX.selectAll("path").attr("stroke", "#363A58");
    gAxisX.selectAll("line").attr("stroke", "#363A58");
    gAxisX
      .append("text")
      .attr("text-anchor", "middle")
      .style("font-size", "30px")
      .attr("fill", "#363A58")
      .attr("x", width / 2)
      .attr("y", 80)
      .text("Fuel consumption");

    ejeY.domain([500, 200]).range([0, height]);
    let gAxisY = svgScatterplot.append("g").call(
      d3
        .axisLeft(ejeY)
        .tickSizeOuter(0)
        .tickFormat((d) => `${d} tons`)
    );
    gAxisY.selectAll("text").style("font-size", "24px").attr("fill", "#363A58");
    gAxisY.selectAll("path").attr("stroke", "#363A58");
    gAxisY.selectAll("line").attr("stroke", "#363A58");
    gAxisY
      .append("text")
      .attr("text-anchor", "end")
      .style("font-size", "30px")
      .attr("fill", "#363A58")
      .attr("transform", "rotate(-90)")
      .attr("y", -140)
      .attr("x", -400)
      .text("Production");
  }

  function crearScatterplot() {
    let main = svgScatterplot.append("g");

    main
      .selectAll("circle.scatter")
      .data(data)
      .join("circle")
      .attr("class", "scatter")
      .attr("cx", function (d) {
        return ejeX(d.consumo);
      })
      .attr("cy", function (d) {
        return ejeY(d.productividad);
      })
      .attr("r", 0)
      .style("fill", "#7FA26F")
      .style("stroke-width", 20)
      .style("stroke", "transparent")
      .on("mouseover", function (event, d) {
        d3.select(event.target)
          .transition()
          .ease(d3.easeCubicOut)
          .style("fill", "#7FA26F");
      })
      .on("mouseout", function (event, d) {
        d3.select(event.target)
          .transition()
          .ease(d3.easeCubicOut)
          .style("fill", "#7FA26F");
      })
      .on("click", function (event, d) {
        actualizarRadar(d);
      })
      .transition()
      .duration(600)
      .attr("r", 12);
  }

  function crearSVGRadar(wrapper) {
    let box = new Box({
      size: { width: widthRadar, height: heightRadar },
      padding: 30,
    });
    widthRadar = box.content.width;
    heightRadar = box.content.height;
    svgRadar = wrapper
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);
    svgRadar = svgRadar
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);
  }

  function crearEjesRadar() {
    let gAxis = svgRadar.append("g").attr("font-family", "sans-serif");

    gAxis
      .selectAll("circle.axis")
      .data(ticks)
      .join((enter) =>
        enter
          .append("circle")
          .attr("class", "axis")
          .attr("cx", widthRadar / 2)
          .attr("cy", heightRadar / 2)
          .attr("fill", "none")
          .attr("stroke", "#363A58")
          .attr("r", (d) => radialScale(d))
      );

    gAxis
      .selectAll("text.ticklabel")
      .data(ticks)
      .join((enter) =>
        enter
          .append("text")
          .attr("class", "ticklabel")
          .style("font-size", "17px")
          .style("text-anchor", "middle")
          .style("font-family", "sans-serif")
          .style("fill", "#363A58")
          .attr("x", widthRadar / 2)
          .attr("y", (d) => heightRadar / 2 + radialScale(d) + 20)
          .text((d) => d.toString() + " hrs")
      );

    let dataCategorias = radarCategories.map((c, i) => {
      let angle = Math.PI / 2 + (2 * Math.PI * i) / radarCategories.length;
      let distLabel = 130;
      c.key == "digging" ? (distLabel -= 0) : distLabel;
      return {
        key: c.key,
        name: c.name,
        angle: angle,
        line_coord: angleToCoordinate(angle, 120),
        label_coord: angleToCoordinate(angle, distLabel),
      };
    });

    svgRadar
      .selectAll("line.axis")
      .data(dataCategorias)
      .join((enter) =>
        enter
          .append("line")
          .attr("class", "axis")
          .attr("x1", widthRadar / 2)
          .attr("y1", heightRadar / 2)
          .attr("x2", (d) => d.line_coord.x)
          .attr("y2", (d) => d.line_coord.y)
          .attr("stroke", "#363A58")
      );

    svgRadar
      .selectAll("text.label")
      .data(dataCategorias)
      .join((enter) =>
        enter
          .append("text")
          .attr("class", "label")
          .style("text-anchor", function (d) {
            let anchor = "middle";
            switch (d.key) {
              case "breaker":
              case "hoist":
                anchor = "end";
                break;
              case "att":
              case "travel":
                anchor = "start";
                break;
              default:
                break;
            }
            return anchor;
          })
          .style("font-family", "sans-serif")
          .style("font-size", "17px")
          .attr("fill", "#363A58")
          .attr("x", (d) => d.label_coord.x)
          .attr("y", (d) => d.label_coord.y)
          .text((d) => d.name)
      );
  }

  function angleToCoordinate(angle, value) {
    let x = Math.cos(angle) * radialScale(value);
    let y = Math.sin(angle) * radialScale(value);
    return { x: widthRadar / 2 + x, y: heightRadar / 2 - y };
  }

  let line = d3
    .line()
    .x((d) => d.x)
    .y((d) => d.y);

  function getPathCoordinates(data_point) {
    let coordinates = [];
    let i = 0;
    for (i = 0; i < radarCategories.length; i++) {
      let key = radarCategories[i].key;
      let angle = Math.PI / 2 + (2 * Math.PI * i) / radarCategories.length;
      coordinates.push(angleToCoordinate(angle, data_point[key]));
    }
    coordinates.push(coordinates[0]);
    return coordinates;
  }

  function crearRadar() {
    gMainRadar = svgRadar
      .append("g")
      .attr("id", "main")
      .attr("font-family", "sans-serif");
    let coordinates = getPathCoordinates(maquina);

    gMainRadar
      .append("path")
      .datum(coordinates)
      .attr("d", line)
      .attr("stroke-width", 3)
      .attr("stroke", "#7FA26F")
      .attr("fill", "#7FA26F")
      .attr("stroke-opacity", 1)
      .attr("fill-opacity", 0.3);

    gMainRadar
      .selectAll("circle.cat")
      .data(coordinates)
      .join("circle")
      .attr("class", "cat")
      .style("fill", "#7FA26F")
      .attr("r", 0)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y);
  }

  function actualizarRadar(d) {
    let coordinates = getPathCoordinates(d);
    gMainRadar
      .select("path")
      .datum(coordinates)
      .transition()
      .ease(d3.easeCubicInOut)
      .duration(600)
      .attr("d", line);

    gMainRadar
      .selectAll("circle.cat")
      .data(coordinates)
      .transition()
      .ease(d3.easeCubicInOut)
      .duration(600)
      .attr("r", 10)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y);
  }

  setup();
});
